
// Generate a unique session ID
const generateSessionId = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

// Get or create session ID
const getSessionId = () => {
  if (typeof window === "undefined") return null;

  let sessionId = sessionStorage.getItem("user_session_id");
  if (!sessionId) {
    sessionId = generateSessionId();
    sessionStorage.setItem("user_session_id", sessionId);
  }
  return sessionId;
};

// Enhanced pushToDataLayer with session tracking
export const pushToDataLayer = (event: any) => {
  if (typeof window !== "undefined" && window.dataLayer) {
    const sessionId = getSessionId();
    const timestamp = new Date().toISOString();

    // Enhance event with session and timing data
    const enhancedEvent = {
      ...event,
      session_id: sessionId,
      timestamp,
      page_url: window.location.href,
      page_title: document.title,
    };

    window.dataLayer.push(enhancedEvent);
  }
};

// User identification (call this when user identifies themselves)
export const identifyUser = (userData: {
  userId?: string;
  userEmail?: string;
  userType?: string;
}) => {
  const sessionId = getSessionId();
  pushToDataLayer({
    event: "user_identified",
    session_id: sessionId,
    ...userData,
  });
};

// Track page views with enhanced data
export const trackPageView = (pageData?: {
  pageName?: string;
  pageCategory?: string;
  [key: string]: any;
}) => {
  pushToDataLayer({
    event: "page_view",
    ...pageData,
  });
};

// Calculator specific events with enhanced tracking
export const trackCalculatorStart = (calculatorType: "payin" | "payout") => {
  pushToDataLayer({
    event: "calculator_start",
    calculator_type: calculatorType,
    funnel_stage: "calculator_initiation",
  });
};

export const trackCalculatorSubmit = (
  calculatorType: "payin" | "payout",
  inputData: {
    payinCount?: string;
    payinAmount?: string;
    payinInternationalPct?: string;
    payinSubscriptionPct?: string;
    payoutCount?: string;
    payoutAmount?: string;
    payoutUsPct?: string;
  }
) => {
  pushToDataLayer({
    event: "calculator_submit",
    calculator_type: calculatorType,
    funnel_stage: "calculator_submission",
    input_payin_count: inputData.payinCount,
    input_payin_amount: inputData.payinAmount,
    input_payin_international_pct: inputData.payinInternationalPct,
    input_payin_subscription_pct: inputData.payinSubscriptionPct,
    input_payout_count: inputData.payoutCount,
    input_payout_amount: inputData.payoutAmount,
    input_payout_uspct: inputData.payoutUsPct,
  });
};

export const trackCalculatorResult = (
  calculatorType: "payin" | "payout",
  result: {
    savingsPct: number;
  }
) => {
  pushToDataLayer({
    event: "calculator_result",
    calculator_type: calculatorType,
    funnel_stage: "calculator_completion",
    savings_percentage: result.savingsPct,
  });
};

// General interaction tracking
export const trackUserInteraction = (
  interactionType: string,
  interactionData: {
    [key: string]: any;
  }
) => {
  pushToDataLayer({
    event: "user_interaction",
    interaction_type: interactionType,
    ...interactionData,
  });
};
